// Path: src\Component\screens\contestdetails.jsx
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  LinearProgress,
  Button,
  Divider,
  Tabs,
  Tab,
  capitalize,
} from "@mui/material";
import Header from "../../Component/Home/header";
import Tournaments from "../../../src/assets/images/Tournaments.png";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as Circlesvg } from "../../../src/assets/images/circle.svg";
import { ReactComponent as Clipcopy } from "../../../src/assets/images/clipcopy.svg";
import { ReactComponent as Iconsvg } from "../../../src/assets/images/icon.svg";
import { ReactComponent as Sharewithfrnds } from "../../../src/assets/images/sharewithfrnds.svg";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { contestInfoApi, contestPlayersApi, contestRulesApi } from "../../apis";
import WinningBreakup from "../Popup/WinningBreakup";
import RightArrow from "../../assets/images/right-arrow.png"
import moment from "moment";
import url from "../../constants/url";

import Loader from "../loader";

import { RWebShare } from "react-web-share";
import ContestPlayers from "./contest-details/contest-players";
import { formatDate, formatTime, shareLink } from "../Utilities/helper";
import { addTrackData, trackerEventId } from "../../config/tracker";

const TabPanel = (props) => {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box className="tab-content">{children}</Box>}
    </div>
  );
};

const Contestdetails = () => {
  const data = [
    { username: "Sujeet2700", winnings: "2000", kills: "-", ranks: "-" },
    { username: "Player1", winnings: "2000", kills: "10", ranks: "Gold" },
    { username: "Player2", winnings: "2000", kills: "10", ranks: "Gold" },
    { username: "Player3", winnings: "2000", kills: "10", ranks: "Gold" },
    { username: "Player4", winnings: "2000", kills: "5", ranks: "Silver" },
    { username: "Sujeet2700", winnings: "2000", kills: "-", ranks: "-" },
    { username: "Player1", winnings: "2000", kills: "10", ranks: "Gold" },
    { username: "Player2", winnings: "2000", kills: "10", ranks: "Gold" },
    { username: "Player3", winnings: "2000", kills: "10", ranks: "Gold" },
    { username: "Player4", winnings: "2000", kills: "5", ranks: "Silver" },
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoader] = useState(false);

  // const redFillStart = percentageFilled; // Start red fill after the percentageFilled
  const redFillWidth = 10; // Width of the red fill
  const [percentageFilled, setPercentageFilled] = useState(0);
  const [percentageRedFillWidth, setRedFillWidthFilled] = useState(0);

  // handle functions
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  const handleBackClick = () => {
    navigate(-1)
  };

  useEffect(() => {
    getContestInfo();
    addTrackData(trackerEventId?.Contest_Details_Shown, { gameCatalogName: `${localStorage.getItem("selectedGameName")}`, gameCatalogId: `${localStorage.getItem("game")}`, contestId: `${id}` });

  }, [])


  useEffect(() => {
    if (selectedTab === 0) {
      getContestInfo();
    }
    else if (selectedTab === 1) {
      setContestPlayers(null);
    }
    else if (selectedTab === 2) {
      getContestRules();
    }
  }, [selectedTab])


  const [contestDetail, setContestDetails] = useState(null);
  const [contestRules, setContestRules] = useState('');
  const [contestPlayers, setContestPlayers] = useState(null);
  const [isPricePointPoolModel, setPricePointPool] = useState(false);
  const [captainInfoCardData, setCaptainInfoCard] = useState([{ username: "Sujeet2700", kills: "-", ranks: "-" }]);

  async function getContestInfo() {
    setIsLoader(true);
    try {
      let response = await contestInfoApi(id, { filter: { user: JSON?.parse(localStorage?.getItem('profile'))?.user_id } });
      if (response?.item && response?.success) {
        setIsLoader(false);
        setContestDetails(response.item);
        setPercentageFilled((response?.item?.totalJoinedPlayers / response?.item?.totalPlayers) * 100)
        setRedFillWidthFilled(response?.item?.totalJoinedPlayers >= response?.item?.minimumSlot ? 0 : ((response?.item?.minimumSlot || 0) / response?.item?.totalPlayers) * 100);
        if (response?.captainInfo && response?.captainInfo?.user !== '') {
          setCaptainInfoCard([
            { username: response?.captainInfo?.userName, kills: response?.captainInfo?.kills > 0 ? response?.captainInfo?.kills : "-", ranks: response?.captainInfo?.rank > 0 ? response?.captainInfo?.rank : "-" }
          ])
        }
      }
      else {
        setIsLoader(false);
      }
    } catch (error) {
      setIsLoader(false);
      //console.log("getTournament error");
    }
  }




  async function getContestRules() {
    setIsLoader(true);
    try {
      let response = await contestRulesApi(id);
      if (response?.rules && response?.success) {
        setIsLoader(false);
        setContestRules(response.rules);
      }
      else {
        setIsLoader(false);
      }
    } catch (error) {
      setIsLoader(false);
      //console.log("getTournament error");
    }
  }
  return (

    <Header title="Contest Details" onBackClick={handleBackClick}>
      {isLoading ? <Loader /> : <></>}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        className="tabs-container player-details-tabs"
        centered
      >
        <Tab label="Details" className="tab" />
        <Tab label="Players" className="tab" />
        <Tab label="Rules" className="tab" />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        <div className="contestdetailscroll">
        <div className="newcontestbox-list" >
        <div className="newcontest-list-title-bar mt-3">
          <div className="newcontestbox-list-title">
          
          {contestDetail?.title?.length > 30 ? <marquee>{contestDetail?.title}</marquee> : contestDetail?.title}
           
            </div>
           
          <div className="newcontestbox-list-status">
          {contestDetail?.status ? capitalize(contestDetail?.status) : ''}
          </div>
        </div>

        {/* basic details */}
        <div className="row newcontest-basicdetails">
          <div className="newcontest--wrap">
            <div className="newcontest">
              <div className="newcontestbox-list-label">Date</div>
              <div className="newcontestbox-list-value">
                <span className="value-wrapper"> {formatDate(contestDetail?.date)}</span>
              </div>
            </div>
            <div className="newcontest">
              <div className="newcontestbox-list-label">Time</div>
              <div className="newcontestbox-list-value">
                <span className="value-wrapper"> {formatTime(contestDetail?.time)}</span>
              </div>
            </div>
            {contestDetail?.titles?.[0]?.isSelection && contestDetail?.titles?.[0]?.name && contestDetail?.titles?.[0]?.value && (
         
            <div className="newcontest">
              <div className="newcontestbox-list-label">{contestDetail?.titles?.[0]?.name}</div>
              <div className="newcontestbox-list-value">
                <span className="value-wrapper truncate-map-box">  {contestDetail?.titles?.[0]?.value}</span>
              </div>
            </div>
              )}
              {contestDetail?.titles?.[1]?.isSelection && contestDetail?.titles?.[1]?.name && contestDetail?.titles?.[1]?.value && (
                
            <div className="newcontest">
              <div className="newcontestbox-list-label">Perspective</div>
              <div className="newcontestbox-list-value">
                <span className="value-wrapper"> {contestDetail?.titles?.[1]?.value}</span>
              </div>
            </div> 
          )}          
          </div>
       
          <div className="row">
            <div className="col-6">
            <div className="newcontestprize--wrap">
                <div className="newcontest">
                  <div className="newcontestbox-list-label">Prize Pool</div>
                  <div className="newcontestbox-list-value-icon">
                    <span>
                      <img className="newcontest-list-currency-icon1" src={url?.image_url + contestDetail?.currency?.[0]?.outCurrency?.img?.default} />
                    </span>
                    <span className="newcontest-list-currency-amount value-wrapper">{contestDetail?.prizePool}</span>
                  </div>
                </div>
                <div className="newcontest">
                  <div className="newcontestbox-list-label">{contestDetail?.winningModel == "perKill" ? "Per Kill" : "Winners"}</div>
                  <div className="newcontestbox-list-value-icon-dropdown">
                  {contestDetail?.winningModel === "perKill" ? (
                    <span className="newcontest-list-currency-amount value-wrapper">  <img className="newcontest-list-currency-icon1" src={url?.image_url + contestDetail?.currency?.[0]?.outCurrency?.img?.default} alt="" /></span>
                  ) : null}
                  <span className="newcontest-list-currency-amount value-wrapper">
                      {contestDetail?.winningModel === "perKill" 
                        ? contestDetail?.killPoints 
                        : contestDetail?.totalWinners}
                    </span>
                    {contestDetail?.winningModel != "perKill" && (
                <div onClick={(e) => { e.stopPropagation(); setPricePointPool(); }}>
                      <img className="newcontest-list-currency-icon" src={RightArrow} />
                    </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <div className="newcontest">
                <div className="newcontestbox-list-label">Join Using</div>
                <div className="newcontestbox-list-value-icon">
                {contestDetail?.entryFee > 0 ? (
                  <span>
                    <img className="newcontest-list-currency-icon1" src={url.image_url + contestDetail?.currency?.[0]?.inCurrency?.img?.default} alt=""  />
                  </span>
                  ) : null}
                  <span className="newcontest-list-currency-amount value-wrapper">  {contestDetail?.entryFee > 0 
    ? contestDetail?.entryFee 
    : "Free"}</span>
                </div>
              </div>
            </div>
            <Grid container spacing={2} className="details-grid slots-info">
              <Grid item xs={12} className="details-grid-box">
                <div className="progress-bar-wrapper mt-3 custome-progressbar">
                  <LinearProgress
                    variant="determinate"
                    value={percentageFilled || 0}
                    className="custom-linear-progress"
                  />
                  <Tooltip
                    title={`Minimum ${contestDetail?.minimumSlot || 0} ${contestDetail?.minimumSlot>1 ?'slots':'slot'} Required`}
                    arrow
                    placement="top"
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          backgroundColor: "#1F3338",
                          border: "1px solid #87A7A6",
                          color: "#86A6A6", // Text color
                          fontSize: "0.75rem",
                          filter: "drop-shadow(0px 14px 74px rgba(0, 0, 0, 0.25))",
                          borderRadius: "4px",
                          padding: "8px",
                          width: "162px",
                          height: "50.758px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        "& .MuiTooltip-arrow": {
                          color: "#1F3338", // Arrow background color
                          "&::before": {
                            boxShadow: "0 0 0 1px #86A6A6", // Simulating white border
                          },
                        },
                      },
                    }}
                    classes={{ tooltip: "custom-tooltip" }}>
                    <div
                      className="progress-bar-red-fill"
                      style={{
                        width: `${percentageRedFillWidth || 0}%`,
                        left: `${percentageFilled || 0}%`,
                        position: "absolute",
                        height: "100%",
                      }}
                    />
                  </Tooltip>
                </div>
              </Grid>
              <Grid item xs={6} className="details-grid-box mt-1">
                <div className="newcontestslots-remaining">{contestDetail?.totalPlayers - contestDetail?.totalJoinedPlayers} {(contestDetail?.totalPlayers - contestDetail?.totalJoinedPlayers)>1?'slots':'slot'} remaining</div>
              </Grid>
              <Grid item xs={6} textAlign="right" className="details-grid-box mt-1">
                <div className="newcontestslots-filled">{contestDetail?.totalJoinedPlayers} {contestDetail?.totalJoinedPlayers>1 ?'slots':'slot'} filled</div>
              </Grid>
              
            </Grid>
            <div className="newcontest-roomidpass-container">
            <Grid container spacing={2} className="details-grid slots-info">
              <Grid
                item
                xs={6}
                textAlign="center"
                className="details-grid-box"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(contestDetail?.roomId);
                }}>
                <div className="newcontest-room-id-label newcontest-details-label">Room ID</div>
                <div className="newcontest-room-id-value">
                  {contestDetail?.roomId !== "" ? (
                    <>
                      {" "}
                      {
                        contestDetail?.roomId?.length > 15 ? 
                        <marquee> {contestDetail?.roomId}</marquee>
                        :contestDetail?.roomId 
                      }{" "}
                      <span>
                        <Clipcopy />
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                textAlign="center"
                className="details-grid-box details-grid-box-1"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(contestDetail?.roomPassword);
                }}>
                <div className="newcontest-password-label newcontest-details-label">Password</div>
                <div className="newcontest-room-id-value">
                  {contestDetail?.roomPassword !== "" ? (
                    <>
                      {
                        contestDetail?.roomPassword?.length >15 ? 
                        <marquee> {contestDetail?.roomPassword}</marquee>
                        :contestDetail?.roomPassword 
                      }{" "}
                      <span>
                        <Clipcopy />
                      </span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          </div>

        </div>

      </div>
          <Box className="tab-panel-box">
            {/* Superpower Section */}
            <div className="superpower-container borderradius">
              <table
                className="superpower-wapper"
                style={{ width: "100%", textAlign: "center" }}
              >
                <thead style={{ background: "#8CFF05" }}>
                  <tr>
                    <th
                      className="tableheading"
                      style={{
                        color: "white",
                        padding: "16px",
                        textAlign: "left",
                      }}
                    >
                      Username
                    </th>

                    <th
                      className="tableheading"
                      style={{
                        color: "white",
                        padding: "16px",
                        textAlign: "center",
                      }}
                    >
                      Kills
                    </th>
                    <th
                      className="tableheading"
                      style={{
                        color: "white",
                        padding: "16px",
                        textAlign: "center",
                      }}
                    >
                      Ranks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {captainInfoCardData.map((item, index) => (
                    <tr
                      key={index}
                      style={{

                        backgroundColor: "transparent",
                      }}
                    >
                      <td
                        className="tablevalues"
                        style={{ padding: "13.5px 16px", textAlign: "left" }}
                      >
                        {item.username}
                      </td>

                      <td
                        className="tablevalues"
                        style={{ padding: "13.5px 16px", textAlign: "center" }}
                      >
                        {item.kills}
                      </td>
                      <td
                        className="tablevalues"
                        style={{ padding: "13.5px 16px", textAlign: "center" }}
                      >
                        {item.ranks}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Box>
          <div onClick={()=>{
            addTrackData(trackerEventId?.Contest_Share_Button_Click, {
              gameCatalogName: `${localStorage?.getItem('selectedGameName')}`,
              gameCatalogId: `${localStorage?.getItem('game')}`,
              contestId: `${contestDetail?._id}`,
            });
          }}>
          <RWebShare
            data={shareLink(localStorage?.getItem('game'), contestDetail?.gameTypeAry?._id, contestDetail?.gameTypeAry?.name, contestDetail?._id, 'contest')}
            // data={{
            //   text: "Hey! Join me on KGeN for this exciting match. Click on the following link and let's play together",
            //   url:
            //   `${url.deep_link}${url?.redirect_url}?gameId=${localStorage?.getItem('game')}&gameType=${contestDetail?.gameTypeAry?._id}&gameTypeName=${contestDetail?.gameTypeAry?.name}&type=contest`
            // }}
            sites={["facebook", "twitter", "whatsapp", "linkedin", "copy"]}
            onClick={() => {
              console.log("shared successfully!")
            }}
          >
            <button className="sharewithfrnds">
              <div className="sharewithfrnds-heading">Share With Friends</div>
              <div className="sharewithfrnds-icon">
                <Sharewithfrnds />
              </div>
            </button>
          </RWebShare>
          </div>
        </div>
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <ContestPlayers tab={selectedTab} contestId={id} contestDetail={contestDetail} />
        {/* <div className="contestdetailscroll">
          <Box className="tab-panel-box">
            <div className="body-scrolltable">
              <div className="superpower-container">
                <table
                  className="superpower-wapper"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <thead style={{ background: "#8CFF05" }}>
                    <tr>
                      <th
                        className="tableheading"
                        style={{
                          color: "white",
                          padding: "16px",
                          textAlign: "left",
                          width: showWinningsColumn ? "25%" : "33.33%", // Adjust width based on Winnings column visibility
                        }}
                      >
                        Username
                      </th>
                      {contestDetail?.status === "completed" && (
                        <th
                          className="tableheading"
                          style={{
                            color: "white",
                            padding: "16px",
                            textAlign: "center",
                            // width: "25%", // Fixed width for Winnings column
                          }}
                        >
                          Winnings
                        </th>
                      )}
                      <th
                        className="tableheading"
                        style={{
                          color: "white",
                          padding: "16px",
                          textAlign: "center", // Align Kills left if Winnings is shown, otherwise center
                          // width: "25%", // Fixed width for Kills column
                        }}
                      >
                        Kills
                      </th>
                      <th
                        className="tableheading"
                        style={{
                          color: "white",
                          padding: "16px",
                          textAlign: "center", // Align Ranks center if Winnings is shown, otherwise left
                          // width: "25%", // Fixed width for Ranks column
                        }}
                      >
                        Ranks
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contestPlayers?.captainData ?
                      <tr
                        key={-1}
                        style={{
                          borderBottom:
                            "1px solid var(--core-neutral-dark, #415F66)",
                          padding: "13.5px 16px",
                          backgroundColor: "#415F66",
                        }}
                      >
                        <td
                          className="tablevalues"
                          style={{ padding: "13.5px 16px", textAlign: "left" }}
                        >
                          {contestPlayers?.captainData?.userName}
                        </td>
                        {contestDetail?.status === "completed" && (
                          <td
                            className="tablevalues"
                            style={{
                              padding: "13.5px 16px",
                              textAlign: "center",
                            }}
                          >
                            <span> <Iconsvg /></span> {contestPlayers?.captainData?.winnings === 0 ? '-' : contestPlayers?.captainData?.winnings}
                          </td>
                        )}
                        <td
                          className="tablevalues"
                          style={{
                            padding: "13.5px 16px",
                            textAlign: "center", // Dynamically set alignment based on Winnings column
                          }}
                        >
                          {contestPlayers?.captainData?.kills === 0 ? '-' : contestPlayers?.captainData?.kills}
                        </td>
                        <td
                          className="tablevalues"
                          style={{
                            padding: "13.5px 16px",
                            textAlign: "center", // Dynamically set alignment based on Winnings column
                          }}
                        >
                          {contestPlayers?.captainData?.rank === 0 ? '-' : contestPlayers?.captainData?.rank}
                        </td>
                      </tr> : ''

                    }
                    {contestPlayers?.data?.length > 0 ? contestPlayers?.data?.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          borderBottom:
                            "1px solid var(--core-neutral-dark, #415F66)",
                          padding: "13.5px 16px",
                          backgroundColor: "inherit",
                        }}
                      >
                        <td
                          className="tablevalues"
                          style={{ padding: "13.5px 16px", textAlign: "left" }}
                        >
                          {item.userName}
                        </td>
                        {contestDetail?.status === "completed" && (
                          <td
                            className="tablevalues"
                            style={{
                              padding: "13.5px 16px",
                              textAlign: "center",
                            }}
                          >
                            <span> <Iconsvg /></span> {item.winnings === 0 ? '-' : item.winnings}
                          </td>
                        )}
                        <td
                          className="tablevalues"
                          style={{
                            padding: "13.5px 16px",
                            textAlign: "center", // Dynamically set alignment based on Winnings column
                          }}
                        >
                          {item.kills === 0 ? '-' : item.kills}
                        </td>
                        <td
                          className="tablevalues"
                          style={{
                            padding: "13.5px 16px",
                            textAlign: "center", // Dynamically set alignment based on Winnings column
                          }}
                        >
                          {item?.rank === 0 ? '-' : item?.rank}
                        </td>
                      </tr>
                    )) : ''}
                  </tbody>
                </table>
              </div>
            </div>
          </Box>
        </div> */}
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <div className="contestdetailscroll">
          <div className="rules-list tab-panel-box" dangerouslySetInnerHTML={{ __html: contestRules }} />
        </div>
      </TabPanel>
      {isPricePointPoolModel && (
        <WinningBreakup
          isOpen={isPricePointPoolModel}
          title="Winning Breakup"
          onClose={() => setPricePointPool(false)}
          prizePool={contestDetail}
          tournaments={contestDetail}
        />
      )}
    </Header>
  );
};

export default Contestdetails;
